@font-face {
    font-family: 'Humanst521 BT';
    src: local('Humanst521 BT Roman'), local('Humanist521BT-Roman'),
        url('../../assets/fonts/subset-Humanist521BT-Roman.woff2') format('woff2'),
        url('../../assets/fonts/subset-Humanist521BT-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
// Override container width
@media only screen and (min-width: 993px){
    .container {
        width: 85%;
    }
}
@media only screen and (min-width: 993px){
    .container {
        width: 85%!important;
    }
}


body{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}
.first-screen{

    margin-top: 163.5px;
    @media(max-width: 992px){
        margin-top: 55px;
    }
    background-image: url(../../assets/images/fsa-lab-banner.png);
    padding-bottom: 40px;
    background-position: bottom right;
    @media(max-width:992px){
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    h1{
        color: $base-blue;
        background-color: rgba(255, 255, 255, 0.55);
        font-size: 56px;
        padding: 15px 30px;
        font-weight: 700;
    }
    .raw-text{
        color: $base-blue;
        background-color: rgba(255, 255, 255, 0.55);
        padding: 25px 45px;
        min-height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 28px;
        font-weight: 700;
        button{
            display: inline-block;
            font-weight: 500;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            transition: all .2s ease-out;
            border: 1px solid #f81100;
            background-color: #f81100;
            padding: 18px 21px;
            font-size: 22px;
            line-height: 1;
            cursor: pointer;
            overflow: hidden;
            position: relative;
            border-radius: 50px;
            color: white;
        }
        margin-top: 40px;
    }
}
.stacked-cons{

    margin-top: 45px;

    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media(max-width: 992px){
        margin-bottom: 25px;
    }

    .item{
        @media(max-width: 992px){
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 20px;
        }
        width: 25%;
        display: flex;
        align-items: center;
        padding: 7px;
        svg{
            width: 40px;
            min-width: 40px;
            margin-right: 15px;
        }
    }
}
.second-block{
    position: relative;
    overflow: hidden;
    padding-bottom: 44px;
    .underlay{
        position: absolute;
        z-index: 5;
    }
    .container{
        position: relative;
        z-index: 15;
    }
    &__left{
        padding-right: 40px!important;
        margin-top: 55px;
        border-left: 2px solid $base-blue;
        @media(max-width:992px){
            text-align: center;
            padding-right: 0px!important;
        }

    }
    &__right{
        color: white;
        &-heading{
            margin-top: 0px;
            color: white!important;
            font-size: 2.92rem;
            line-height: 110%;
            margin: 1.9466666667rem 0 1.168rem 0;
        }
        p{
            opacity: 0.6;
        }
        text-align: right;
        padding-left: 40px!important;
        margin-top: 55px;
        @media(max-width:992px){
            background-color: $base-blue;
            text-align: center;
            padding-left: 0px!important;
            padding: 10px!important;
        }
    }
}

h3,h2{
    color: $base-blue!important;
    font-weight: 700!important;
    text-align: center;
    @media(max-width:992px){
        font-size: 28px!important;
    }
}
.green-line {
    width: 15%;
    height: 3px;
    background-color: #0d47a1;
    border-radius: 0px;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 22px;
}
h1{
    @media(max-width:992px){
        font-size: 42px!important;
    }
}
.modal{
    strong{
        font-weight: 800;
        margin-bottom: 25px;
        margin-top: 25px;
        display: block;
    }
    &-policy{
        max-height: 80%!important;
        width: 80%!important;
    }
    
    &-overlay{
    }
    h4{
        color: rgb(0, 84, 165);
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        border-bottom: 1px solid;
        padding-bottom: 15px;
    }
    .form{
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
        margin-top: 15px;
        padding: 10px 30px;

        &__title{
            color: $base-blue;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        &__input{
            box-sizing: border-box!important;
            color: grey!important;
            overflow: hidden!important;
            white-space: nowrap!important;
            height: 34px!important;
            font-size: 14px!important;
            padding-left: 16px!important;
            line-height: 1.42857143!important;
            color: #555!important;
            background-color: #fff!important;
            background-image: none!important;
            border: 1px solid #ccc!important;
            border-radius: 4px!important;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075)!important;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075)!important;
            -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s!important;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s!important;
        }
        &__btn{
            background-color: $base-blue;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-style: solid;
            border-top-width: 1px;
            border-bottom-width: 3px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-color: rgb(8, 78, 138);
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 16px;
            padding-right: 16px;
            font-size: 18px;
            color: rgb(255, 255, 255);
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0,.25);
            width: 100%;
            display: block;
            outline: 0!important;
            box-shadow: none;
            position: relative;
            border-width: 0;
            overflow: hidden;
            white-space: normal;
            word-wrap: break-word;
            transition-property: box-shadow,transform,color,background-color,border-color!important;
            transition-timing-function: cubic-bezier(.2,1,.3,1);
        }
        &__policy{
            margin-top: 5px;
            text-align: center;
            opacity: .5;
            font-size: 80%;
            cursor: pointer;
            display: block;
            text-align: center;
        }
        &__bot{
            text-align: center;
            color: $base-blue;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }
}

.custom-btn {
    position: relative;
    overflow: hidden;
   -webkit-transition: ease 0.3s;
    transition: ease 0.3s;
}

.custom-btn:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fa4923;
    opacity: 0;
    z-index: 1;
    -webkit-transition: ease 0.3s;
    transition: ease 0.3s;
}

.custom-btn:after {
    content: "";
    display: block;
    width: 30px;
    height: 300px;
    margin-left: 60px;
    background: #fff;
    opacity: .7;
    position: absolute;
    left: -40px;
    top: -150px;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    -webkit-animation-name: slideme;
    animation-name: slideme;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-delay: 0.05s;
    animation-delay: 0.05s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@-webkit-keyframes slideme { 0% { left: -30px; margin-left: 0px; }
  30% { left: 110%; margin-left: 80px; }
  100% { left: 110%; margin-left: 80px; } }

@media(max-width: 992px){
h2{
    font-size: 2.56rem!important;
    text-align: center!important;
}
}