@import 'reset';
@import 'variables';
@import 'globals';

@import 'mixins';
@import 'header';
@import 'footer';

@import 'service';
@import 'callback';
@import 'docs';
@import 'advantages';
@import 'zaboti';
@import 'reasons';
@import 'problems';
@import 'about';
@import 'contacts';