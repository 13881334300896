footer.page-footer{
    border-top: 3px solid #3f4245;
    background-color: white;
    padding-bottom: 58px;
    padding-top: 55px;
    .footer-additional{
        a{
            color: #00e4ff;
        }
        span{
            color: #00e4ff;
        }
    }
    .footer-text{
        color: black;
    }
}