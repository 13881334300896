@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-down {
    @media (max-width: 600px) { @content; }
}
@mixin for-tablet-landscape-down {
    @media (max-width: 992px) { @content; }
}
@mixin for-desktop-down {
    @media (max-width: 1200px) { @content; }
}
@mixin for-big-desktop-down {
    @media (max-width: 1800px) { @content; }
}