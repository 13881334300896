.problems{
    background: url(../../assets/images/3.jpg);
    padding-bottom: 65px;
    background-position: bottom;

    @media(max-width:992px){
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    &__list{
       .item{
           text-align: center;

           &__number{
               font-size: 52px;
               color: $base-blue!important;
               font-weight: 700;
           }
           &__title{
            color: $base-blue!important;
            font-weight: 700;
            font-size: 20px;
           }
           &__text{
               font-size: 16px;
           }
       } 
    }
}