.advantages{
    h3{
        text-align: center;
    }
    &__list{

    }
    .list-item{
        display: flex;
        align-items: center;

        &-reverse{
            flex-direction: row-reverse;
        }

        &-image{
            display: block!important;
            width: 15%;
            margin-left: 30px;
            margin-right: 30px;
        }
        &-title{
            font-weight: 700;
            font-size: 20px;
        }   
        &-text{

        }
        &-wrapper{
            width: 85%;
        }
    }
}