.our-services{
    h3{
        text-align: center;
        color: $base-blue!important;
        font-weight: 700;
        margin-top: 65px;
    }
    .service-card{
        color: $base-blue!important;
        font-weight: 700;
        text-align: center;
        margin-bottom: 15px;
        min-height: 485px!important;
        height: 485px;
        &__title{

        }
        &__image{
            min-height: 245px;
            max-height: 245px;
            overflow: hidden;
            img{
                margin: 0 auto;
            }
        }
        &__price{
            margin: 10px 0px;
        }
        &__time{
            margin: 10px 0px;
        }
        &__btn{
            margin: 10px 0px;
            a{
                color: white;
                background-color: rgb(9, 97, 173);
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                border-style: solid;
                border-top-width: 1px;
                border-bottom-width: 1px;
                border-left-width: 1px;
                border-right-width: 1px;
                border-color: rgb(7, 78, 138);
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 17px;
                font-weight: bold;
                color: rgb(255, 255, 255);
            }
        }
        .green-line{
            width: 15%;
            height: 3px;
            background-color: #0d47a1;
            border-radius: 0px;
            margin: 0 auto;
            margin-top: 15px;
            margin-bottom: 22px;
        }
    }
}