.callback{
    background: url(../../assets/images/3.jpg);
    padding-bottom: 65px;
    @media(max-width:992px){
        background-repeat: no-repeat;
        background-size: cover;
    }
    h3{
        padding-left: 10%;
        padding-right: 10%;
        text-align: center;
        text-align: center;
    }
    &-block{
        display: flex;
        align-items: center;
        @media(max-width:992px){
            flex-wrap: wrap;
        }
        ul{
            width: 50%;
            @media(max-width:992px){
                width: 100%;
            }
            .form{
                width: 50%;
            }
            li {
                padding-left: 16px;
            }
            
            li:before {
            content: "✓"; /* Insert content that looks like bullets */
            padding-right: 8px;
            color: $base-blue /* Or a color you prefer */
            }
        }
    }
    .form{
        width: 100%;
        max-width: 450px;
        background: white;
        margin: 0 auto;
        margin-top: 45px;
        padding: 10px 30px;

        &__title{
            color: $base-blue;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        &__input{
            box-sizing: border-box!important;
            color: grey!important;
            overflow: hidden!important;
            white-space: nowrap!important;
            height: 34px!important;
            font-size: 14px!important;
            padding-left: 16px!important;
            line-height: 1.42857143!important;
            color: #555!important;
            background-color: #fff!important;
            background-image: none!important;
            border: 1px solid #ccc!important;
            border-radius: 4px!important;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075)!important;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075)!important;
            -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s!important;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s!important;
        }
        &__btn{
            background-color: $base-blue;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-style: solid;
            border-top-width: 1px;
            border-bottom-width: 3px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-color: rgb(8, 78, 138);
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 16px;
            padding-right: 16px;
            font-size: 18px;
            color: rgb(255, 255, 255);
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0,.25);
            width: 100%;
            display: block;
            outline: 0!important;
            box-shadow: none;
            position: relative;
            border-width: 0;
            overflow: hidden;
            white-space: normal;
            word-wrap: break-word;
            transition-property: box-shadow,transform,color,background-color,border-color!important;
            transition-timing-function: cubic-bezier(.2,1,.3,1);
        }
        &__policy{
            margin-top: 5px;
            text-align: center;
            opacity: .5;
            font-size: 80%;
            cursor: pointer;
            display: block;
            text-align: center;
        }
        &__bot{
            text-align: center;
            color: $base-blue;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }
}