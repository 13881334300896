.reasons{
    padding-bottom: 40px;
    h3{
        text-align: center;

    }
    &__subhead{
        color: rgb(51, 51, 51);
        font-size: 14px;
        text-align: center;
    }
    &-list{
        display: flex;
        margin-top: 35px;
        flex-wrap: wrap;
        .item{
            width: 20%;
            @media(max-width:992px){
                width: 50%;
            }
            @media(max-width:600px){
                width: 100%;
            }
            text-align: center;
            box-sizing: border-box;
            padding: 0px 10px;
            &-number{
                font-weight: 700;
                font-size: 64px;
                color: $base-blue!important;
            }
            &-text{
                color: $base-blue!important;
                font-weight: 700;
            }
        }
    }
}