.zaboti{
    background-image: url(../../assets/images/3.jpg);
    padding-bottom: 55px;
    @media(max-width:992px){
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__subHeader{
        color: rgb(51, 51, 51);
        text-align: center;
        margin-top: 15px;
        margin-bottom: 20px;
    }
    h3{
        text-align: center;
        color: $base-blue;
    }
    &-list{
        display: flex;
        flex-wrap: wrap;
    }
    &-item{
        text-align: center;
        margin-bottom: 40px;
        &-img{
            img{
                margin: 0 auto;
                max-width: 100px;
            }
        }
        &-title{
            color: $base-blue;
            font-size: 20px;
            margin: 10px 0px;
        }
        &-text{
            font-size: 14px;
        }
    }
    
    &__form{

        max-width: 67%;
        width: 67%;
        margin: 0 auto;
        border-top-style: solid;
        border-top-width: 2px;
        border-top-color: rgb(0, 194, 255);
        border-bottom-style: solid;
        border-bottom-width: 2px;
        border-bottom-color: rgb(0, 194, 255);
        border: 2px solid rgb(0, 194, 255);

        padding: 15px 25px;

        &-title{
            // color: $base-blue;
            color: $base-red;
            font-weight: 700;
            font-size: 16px;
        }
        &-wrapper{
            
        }

        &-policy{
            margin-top: 5px;
            text-align: center;
            opacity: .5;
            font-size: 80%;
            cursor: pointer;
        }
        &-btn{
            color: white;
            background-color: rgb(9, 97, 173);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-style: solid;
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-color: rgb(7, 78, 138);
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 17px;
            font-weight: bold;
            color: rgb(255, 255, 255);
        }
    }
}