.docs{
    h3{
        text-align: center;
    }
    &_subhaed{
        font-weight: 700;
        text-align: center;
        font-size: 20px;
        margin-bottom: 75px;
    }
    &__list{
        &-item{
            display: flex;
            align-items: center;
            svg{
                max-width: 60px;
                width: 60px;
                display: inline-block;
                fill: $base-blue;
            }
            &-tick{
                width: 40px;
            }
            border-bottom: 1px solid $base-blue;
            margin-bottom: 5px;
            padding-bottom: 5px;
        }
    }
}