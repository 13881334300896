.header{
    font-family: Arial, Helvetica, sans-serif;
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;

    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgb(10, 97, 173);

    background-color: rgba(255,255,255,.92);

    @media(max-width:992px){
        background: white;
    }
    &__wrapper{
        // max-width: 80%;
        // margin: 0 auto;
        // display: flex;
        // align-items: center;
        .fl{
            display: flex;
            align-items: center;
        }
        a{
            &.active{
                background-color: $base-blue;
                border-color: $base-blue;
                color: rgb(255, 255, 255);
            }
            &:hover{
                background-color: $base-blue;
                border-color: $base-blue;
                color: rgb(255, 255, 255);
            }
            transition: all .5s;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-style: solid;
            border-top-width: 2px;
            border-bottom-width: 2px;
            border-left-width: 2px;
            border-right-width: 2px;
            border-color: $base-blue;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 5px;
            padding-right: 5px;
            font-size: 14px;
            font-weight: bold;
            color: $base-blue;
        }
    }
    &__logo{
        max-width: 150px;
        margin-top: 15px;
    }
    &__links{
        display: flex;
        justify-content: space-between;
    }
    &__info{
        display: flex;
        flex-direction: column;

        text-align: center;
        a{
            &:hover{
                background: transparent!important;
                color: $base-blue!important;
            }
        }
    }
    .mobile-navs{
        li{
            a{
                margin-bottom: 10px;
                display: inline-block;
            }
        }
    }
    .collapsible{
        padding-bottom: 0px;
        box-shadow: none;
        border: none;
        border-bottom: 0px;
    }
}
.info{
    padding: 0px;
    &__order{
        background-color: $base-blue;
        border-color: $base-blue;
        color: rgb(255, 255, 255)!important;

        display: flex;
        align-items: center;

        i{
            margin-right: 5px;
        }
    }
}
.clear-link{
    border: none!important;
}