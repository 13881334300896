.about{
    background: url(../../assets/images/3.jpg);
    padding-bottom: 65px;

    @media(max-width:992px){
        background-repeat: no-repeat;
        background-size: cover;
    }
    background-position: center;
    &__sub{
        font-size: 28px;
        line-height: 1.4;
        margin-bottom: 30px;
    }
    p{
        color: $base-blue!important;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
    }
}